import React from "react";

export const Privacy = (props) => {
  return (
    <div>
      <div id="privacy">
        <div className="container">
          <p>Privacy Notice of Learntastic.app</p>

          <p>JSBB INFONET Private Limited (the “Company”, “We”, “Us”, “Our” or “Learntastic”) are committed to protecting your Personal Data and supports the right to your privacy. This Privacy Notice (“Notice”) explains what and how Personal Data is collected, and further processed by us, sources (if any) of Personal Data and to whom the Personal Data is disclosed.</p>


         <p> Following are the various sections of the Privacy Notice. To understand in detail how we process and protect your Personal Data, please go through each of the sections below:</p>         
         <h4> 1. What Data is collected?</h4>

         <p> We may collect the following Personal Data attributes through Our Website:</p>

         <p> First Name</p>
         <p> Last Name</p>
         <p> Email Address</p>
         <p> Phone Number</p>
         <p> Picture</p>
         <p> Location (City, Country)</p>
         <p> Payment / Billing Details</p>
         <p> Activity Data (IP address, website usage data, etc.)</p>
<p></p>
         <p> Cookies: We also use cookies and similar technologies for purposes such as analyze trends, enhance and personalize your experience and improve Our Services. Please refer to Our Cookie Policy, to learn more.</p>

          You are responsible for any third party Personal Data obtained, published or shared with Us and confirm that you have the third party's consent to provide the Data with Learntastic.

          <h4>2. Processing Children’s Data</h4>

          Protecting the privacy of children is very important to Us. Our Website is not intended for children and We do not knowingly collect Personal Data from children. If you are under 16 years of age, then please do not use or access Our Website at any time or in any manner. If we learn that Personal Data has been provided to us and/or collected on the Website from children, such as through a support request, we take appropriate steps to delete that Data in a secure manner. If you are a parent / legal guardian and you discover that your child has registered for an account on Our Website, then you have the right to request us to delete such Data by contacting our privacy team at “privacy@Learntastic.com”.


          <h4>3. Why do We use your Data?</h4>

          We shall use your Data for the below purposes:

          To enable you to register for Learntastic;
          To respond to your queries and fulfil your requests;
          To provide you support and assistance;
          To process your orders or other billing transactions;
          To send emails and other communications according to your preferences or that display content that we think may interest you including reports, new features and promotional offers, etc;
          To monitor, analyze and describe usage patterns and performance of the service, including aggregate metrics such as total number of visitors, traffic, and demographic patterns;
          To improve Our services, including testing, research, internal analytics and product development;
          To notify you about changes to Our Website and its services;
          To comply with legal requirements, and to protect Our legal rights or the rights of others.
          4. How We protect your Data?

          We endeavour, where practicable, to process your Personal Data in a safe environment by preventing any unauthorized or unlawful processing of Personal Data or accidental loss or destruction of, or damage to such information. We have implemented various physical, technical and administrative security measures to protect your Personal Data and Our network from unauthorized access.

          Some of these measures include:

          encryption in transit or at rest;
          strict adherence to privacy and security practices;
          restriction of access to Personal Data to personnel who have a need to know such Data.
          <h4>5. Disclosure of your Data</h4>

          In some cases, we may disclose your Personal Data with external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies). We shall ensure that all such disclosures shall be in accordance with applicable privacy laws (such as executing Data Processing Agreements).

          In some circumstances, Personal Data may also be transferred outside the country of your residence. Such transfers shall be in accordance with applicable privacy laws via implementation of appropriate safeguards (such as Data Transfer Agreements).

          It should be noted that these external parties are not used for behavioral tracking of children.

          For more further information on Our appointed external parties, please go through the below details:


          <h4>Analytics</h4>
          The external parties in this section enable the Us to monitor and analyze web traffic and can be used to keep track of User behaviour.          

          <h4>Hosting and backend infrastructure</h4>
          This type of service has the purpose of hosting Data and files that enable the Website to run and be distributed as well as to provide a ready-made infrastructure to run specific features or parts of this Website.

          Some services among those, may work through geographically distributed servers, making it difficult to determine the actual location where the Personal Data is stored.

          <h4>Managing contacts and sending messages</h4>
          This type of service makes it possible to manage a database of email contacts, phone contacts or any other contact information to communicate with the User. These services may also collect Data concerning the date and time when the message was viewed by the User, as well as when the User interacted with it, such as by clicking on links included in the message.

          Personal data collected: First and Last name, Email address.


          <h4>Managing support and contact requests</h4>
          This type of service allows Us to manage support and contact requests received. The Personal Data processed depend on the information provided by the User in the messages and the means used for communication (e.g. email address).

          Personal data collected: First and Last name, Email address, provided on a voluntary basis


          <h4>Registration and authentication</h4>
          By registering or authenticating, Users allow Us to identify them and give them access to dedicated services. In this case, We will be able to access some Data, stored by these third party services, for registration or authentication purposes.

          Personal data collected: First and Last name, Email address, phone number


          <h4>SPAM protection</h4>
          This type of service analyzes the traffic of this Website, potentially containing Users' Personal Data, with the purpose of filtering it from parts of traffic, messages and content that are recognized as SPAM.

          <h4>6. Retention of your Data</h4>

          We shall retain your Personal Data for as long as it is necessary for the purpose(s) it was collected for. We may also retain and use your Personal Data for a longer period as permitted or required by law, to comply with Our legal, tax or regulatory obligations (e.g. audit and accounting requirements), handle disputes, to exercise or defend claims. We shall determine the retention period for your Personal Data on the basis of local law requirements and business requirements, post which we shall take measures to securely dispose your Personal Data.

          <h4>7. The rights of Users</h4>

          We respect your rights and privacy by taking steps to ensure that your Personal Data is accurate and up to date.

          We assure you that:

          you have the right to know what Personal Data we have about you;
          you have the right to request a copy of your Personal Data;
          you have the right to rectify your Personal Data to ensure it is accurate, complete and up to date;
          you have the right to withdraw your consent from Our processing of your Personal Data;
          you have the right to prevent processing of your Personal Data related to direct marketing activities.

          How to exercise these rights?
          Any requests to exercise your rights can be directed to Our Privacy Team at support@learntastic.com”


          How to delete your account?
          In the event, if the User wants to delete their account, they can reach out to Our Privacy Team at support@Learntastic.com”.

          If you are not satisfied with the way have handled your request or you would like to make a complaint about the way we process your personal data, you can contact the relevant Data Protection Authority (DPA). Please contact us at support@Learntastic.com to find out more.


          How and when we are expected to handle your request?
          We will confirm receipt of your verifiable request within 30 days and provide information about how we will process your request. We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfil your request.

          We do not charge a fee to process or respond to your verifiable request unless such request is manifestly unfounded or excessive. In such cases, we may charge a reasonable fee, or refuse to act on the request. In either case, we will communicate Our choices and explain the reasons behind it.

          <h4>8. Rights for Our Californian consumers</h4>

          <p>If you are residing in California, the CCPA provides you with specific rights regarding your Personal Information. This section describes your CCPA rights and explains how to exercise those rights. If there are any conflicts between this section and any other provision of this Privacy Notice and you are a California resident, the section that is aligned more protective of your Personal Information shall hold on top of the others.</p>

          <p>The right to know - You have the right to request to know the categories of Personal Information that We collect about you, the purposes for which We use your Personal Information and with whom such information is shared. The details on Personal Information that you can request shall be limited to the information collected or used over the past 12 months.</p>

          <p>The right to delete - You have the right to request that we delete the Personal Information that we have collected about you. Under the CCPA, this right is subject to certain exceptions: for example, we may need to retain your Personal Data to provide you with the Services or due to a legal obligation. If your deletion request is subject to one of these exceptions, we may deny your deletion request.</p>

<p>The right to opt-in and opt-out of sale of Personal Information - You are entitled to direct us to stop disclosing your Personal Information to third parties for monetary or other valuable consideration.</p>

<p>The right to non-discrimination - We will not discriminate against you for exercising your rights under the CCPA. We will not deny you Our services, charge you different rates for Our services, or provide you a lower quality of services if you exercise your rights under the CCPA.</p>

<p>      How to exercise these rights?</p>

<p>      To exercise the rights described above, you need to submit your verifiable request to us on “privacy@Learntastic.com”.</p>

<p>      For us to respond to your request, it’s necessary that we know who you are. Therefore, you can only exercise the above rights by making a verifiable request which must:</p>

<p>      provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative;
          describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
          We shall not respond to any request if we are unable to verify your identity and therefore confirm the personal information in Our possession actually relates to you. If you cannot personally submit a verifiable request, you can authorize a person registered with the California Secretary of State to act on your behalf. If you are an adult, you can make a verifiable request on behalf of a minor under your parental authority. You can submit a maximum number of 2 requests over a period of 12 months.</p>


          How and when we are expected to handle your request?
          We will confirm receipt of your verifiable request within 10 days and provide information about how we will process your request. We will respond to your request within 45 days of its receipt. Should we need more time, we will explain to you the reasons why, and how much more time we need. In this regard, please note that we may take up to 90 days to fulfil your request.

          We do not charge a fee to process or respond to your verifiable request unless such request is manifestly unfounded or excessive. In such cases, we may charge a reasonable fee, or refuse to act on the request. In either case, we will communicate Our choices and explain the reasons behind it.

          <h4>9. Contact Us</h4>

          Email Address: support@bleamlabs.com

          <h4>10. Updates to the Privacy Notice</h4>

          We reserve the right to amend, modify, vary or update this Privacy Notice, at Our sole discretion from time to time, as and when the need arises. The most recently published Privacy Notice shall prevail over any of its previous versions. You are encouraged to check this Privacy Notice from time to time to stay informed of any changes.

          This notice was last updated on September 28, 2023
        </div>
      </div>
    </div>
  );
};
