import React from "react";

export const Terms = (props) => {  
  return (
    <div>
      <div id="terms">
        <div className="container">
        <h1>Learntastic Terms of Service</h1>
Welcome to Learntastic - Early Learning Adventures ("Learntastic", the "App", or "Learntastic App", the "Website") owned and developed by JSBB INFONET Pvt Ltd ("JSBB INFONET Pvt Ltd", "we", "us", "our" or "Company"). The following Terms of Service, along with our Privacy Policy create a legal contract (the "agreement" ) between you ("you" or "your"), on one hand, and JSBB INFONET Pvt Ltd, with main office in the state of Maharashtra (India), in the city of Mumbai at 9th Floor, Lotus Business Park, Veera Desai Ind Estate, Off New Link Road, Andheri West, Mumbai 400053, on the other hand, governing your use of all the content we provide through the App, ("Content"). By using or accessing the App, or subscribing to our Content, or making In-App purchases (collectively "Use Learntastic”, "Use of Learntastic" or "Using Learntastic"), you accept and agree to be bound by the terms and provisions of this agreement. In addition, when using this App, you shall be subject to any posted guidelines or rules applicable to the same, which may be posted and modified from time to time. All such guidelines or rules are hereby incorporated by reference into the Terms of Service.

If you do not agree with these terms in their entirety, then you may not continue your use of Learntastic.

By entering this agreement, you specifically agree (i) that arbitration is the exclusive dispute resolution method for all your disputes with JSBB INFONET Pvt Ltd; and (ii) to waive any and all of your rights to pursue or participate in a class action against JSBB INFONET Pvt Ltd, its affiliates, and related parties with regards to the App and/or the site.
<h4>PRIVACY</h4>
<p>
At Learntastic, we are committed to protect your privacy. We are required to process your personal data to provide you with our services. Please go through our Privacy Policy to better understand how we process and safeguard your personal data.
</p>
<h4>THIRD PARTY LINKS</h4>
<p>Certain content, products, and services available via our App/Website may include materials using third parties. Third-party links on our App/Website may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third parties.
</p>
We are not liable for any harm or damages related to the purchase or use of services, resources, content, or any other transactions made in connection with any third-party websites. Complaints, claims, concerns, or questions regarding third-party products should be directed to the respective third parties.
<h4>INTELLECTUAL PROPERTY RIGHTS</h4>
All content included in or made available through the App, such as songs, games, activities, stories, text, graphics, images, audio clips, music, videos and digital downloads and the trademarks, service marks, logos and button icons contained therein is the property of or licensed to JSBB INFONET Pvt Ltd and subject to copyright and other rights protected by Indian and international intellectual property laws.
<h4>LICENSE AND ACCESS</h4>
As a user of the App, you are granted a non-exclusive, non-transferable, revocable, and limited license to access and use the App (and its associated Content). We may terminate this license at any time for any reason, whatsoever. You may use the App and/or Content for your own personal, non-commercial use. No part of the App and/or Content may be reproduced in any form or incorporated into any information retrieval system, electronic or mechanical. You may not use, copy, emulate, clone, rent, lease, sell, modify, decompile, disassemble, reverse engineer, or transfer the App, Content, or any portion thereof. You may not use any automated means or form of scraping or data extraction to access, query or otherwise collect information from the App, or Content except as expressly permitted by us. We reserve any rights not explicitly granted in these Terms of Service. You may not use any device, software, or routine to interfere or attempt to interfere with the proper working of the App and/or Content. You may not take any action that imposes an unreasonable or disproportionately large load on our infrastructure. The license provided is solely for obtaining information about the App and/or the associated Content and to register for a subscription. Visitors of the Platform are not authorized to access any Products & Services or Content and/or data available to members.
<h4>COPYRIGHT COMPLAINTS</h4>
Learntastic respects the intellectual property of others. If you believe that your work has been copied in a way that constitutes copyright infringement, please contact us at support@paperboatapps.com
<h4>BASIC (FREE) SERVICE</h4>
A limited part of the content will be made available to the free users of the App and unlocking any additional content will require either in-app purchase or registering for subscription service.
<h4>SUBSCRIPTION SERVICE AND BILLING</h4>
Subscribed users will have complete access to all the content in the app. By agreeing to subscribe to Learntastic - Early Learning Adventures, you agree to pay the periodic subscription fee displayed within the App as well as at the time of checkout. Subscribed users are also required to agree to the terms and conditions of the specific payment method chosen to subscribe through App Store or Google Play Store or Amazon Appstore or Stripe (our payment processor). All subscribed users will be automatically charged the subscription fees on a monthly, half yearly or yearly basis and Learntastic may change the subscription fees at any time, at its own discretion. If you do not agree to this fee change, you may cancel your subscription. Your continued Use of Learntastic after the notice of change in fees constitutes your agreement to the new fees.
<h4>IN-APP PURCHASES AND BILLING</h4>
By agreeing to opt for an In-App purchase, you agree to pay the required amount and are also required to agree to the terms and conditions of the specific payment method chosen to subscribe through App Store or Google Play Store or Amazon Appstore. Learntastic may change the price of any or all the packs available for In-App purchase, at its own discretion. If you do not agree to this price change, you may not make the respective In-App purchase.
<h4>BILLING DISPUTES</h4>
Apple, Google, Amazon, and Stripe takes care of all the billing and we DO NOT store your credit card information. Any billing disputes or queries can be submitted to Apple, Google, Amazon, Stripe or to support@paperboatapps.com. In your email to us, please provide your "Name" and the "Date of Charge" that you are disputing. We will evaluate the claim and issue a decision based on our discretion.
<h4>APP PERMISSIONS</h4>
When you use the Learntastic App, you may grant certain permissions to us for your device. Most mobile devices provide you with information about these permissions.
<h4>YOUR RESPONSIBILITY FOR EQUIPMENT AND RELATED COSTS</h4>
with obtaining and maintaining all phone, computer hardware, Internet access services and other equipment or services needed to access and use Learntastic. You must provide all equipment and software necessary to connect to the App and are responsible for ensuring that the foregoing do not disturb or interfere with the App's operations. If any upgrade in or to the App requires changes in your equipment or software, you must effect these changes at your own expense. Unless explicitly stated in writing by JSBB INFONET Pvt Ltd, any new or additional features of the App, including the release of new content, shall be subject to these Terms of Service. We are not responsible for any loss or harm caused to your equipment or hardware for any use of Learntastic, or alleged use of Learntastic.

If a User cannot access Learntastic because they fail to meet the required system requirements, then Learntastic will not take responsibility or give a refund to the User. It is the User’s responsibility to test their system for any compatibility issues prior to subscribing or registering with Learntastic.
<h4>WARRANTY DISCLAIMER</h4>
Your use of the App, Website, is solely at your own risk. The App, Website and the associated content are provided “As Is” and “As Available” without warranty of any kind by the Company or its affiliates. To the maximum extent allowed by applicable law, the Company and its affiliates, expressly disclaim all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose and any warranty of non-infringement. There is no warranty that the Learntastic App and Website will meet your requirements or that your access to the same will be uninterrupted, timely, secure, or error-free. Neither JSBB INFONET nor its affiliates, warrant, guarantee or make any representations regarding the use or the results of the use of the Learntastic App with respect to performance, accuracy, reliability, security capability, correctness or otherwise.

The Company uses reasonable efforts to ensure that information about any of the content on the Learntastic App is correct. However, the Company cannot guarantee the accuracy of such information and will not be liable for any errors, omissions, or other deficiencies.

Because some jurisdictions do not allow certain warranties to be disclaimed, the above disclaimer may not apply in full to you. To the extent that in a particular circumstance any disclaimer set forth herein is prohibited by applicable law, then, instead of the provisions hereof in such particular circumstance, the company and/or its affiliates shall be entitled to the maximum disclaimer available at law or in equity by such applicable law in such particular circumstance.
<h4>LIMITATION OF LIABILITY</h4>
You acknowledge that you, or anyone else using the Learntastic App will use it at your/his/her own risk of property and personal injury, whether such injury is foreseeable or not, including, but not limited to, seizures, vision problems, or other symptoms related to the use of Learntastic. In fact, you acknowledge that the use of the media and contents we provide may result in personal injury, including, but not limited to, seizures or vision problems, and that you, and anyone else using the App from your device, is fit for such use.

In no event shall we be liable to you or any third party, whether in contract, tort (including negligence), strict liability, indemnification or otherwise, for any consequential, incidental, indirect, special or punitive damages arising out of or in connection with your use of the Learntastic App, in any manner, foreseeable or otherwise. Also, we shall not be liable for your or one's inability to access the Learntastic App, nor shall we be liable to you or any third party as to any virus or malicious code on your or their devices allegedly resulting from the use of Learntastic App; even if JSBB INFONET and/or its affiliates have been advised of the possibility of such damages.

The total cumulative liability of JSBB INFONET, its affiliates and/or third party suppliers to you or to any third parties, from all causes of action and under all theories of liability will be limited to and will not exceed the greater of the fees you paid to pursuant to this agreement over the twelve (12) months preceding the incident giving rise to such liability. Because some jurisdictions do not allow certain limitations of liability, portions of the above limitations may not apply to you. To the extent that in a particular circumstance any limitation on damages or liability set forth herein is prohibited by applicable law, then, instead of the provisions hereof in such particular circumstance, the Company and/or its affiliates, shall be entitled to the maximum limitations on damages and liability available at law or in equity by such applicable law in such particular circumstance.
<h4>INDEMNIFICATION</h4>
You agree that you shall defend, indemnify and hold harmless the Company and its officers, directors, shareholders, employees, independent contractors, agents, representatives, partners, licensors, suppliers and affiliates (collectively, "Affiliates") and other users of the Learntastic App from and against all claims, actions, demands, liabilities, settlements and expenses, including, but not limited to, attorneys' fees and costs, arising out of or related to: (i) any breach or violation by you of these Terms of Service or the Privacy Policy or any applicable law; (ii) your access or Use of Learntastic App; (iii) any third party's access or Use of Learntastic App; and (iv) your use of any of the Learntastic Content.
<h4>GOVERNING LAW</h4>
These Terms of Use will be interpreted and construed in accordance with the laws of Republic of India without reference to its rules of conflicts of law.
<h4>DISPUTES</h4>
The Company and you agree to arbitrate all disputes between us, except disputes relating to the enforcement of our intellectual property rights. Dispute means any dispute, action or other controversy between you and us concerning the Learntastic App and Website and the associated Content or these terms, whether in contract, tort, warranty, statute or regulation, or other legal or equitable basis.

There is no judge or jury in arbitration, and court review of an arbitration award is limited. However, an arbitrator can award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages) and must follow the terms of these Conditions of Use as a court would.

In the event of a Dispute, You must send a notice of Dispute to the Company, which is a written statement that sets forth the name, address and contact information of the party giving the notice, the facts giving rise to the Dispute, and the relief requested. You must send any notice of Dispute to JSBB INFONET Private Limited, Attention: Legal/Arbitration Notice. We will send any notice of Dispute to you at the contact information we have for you.

Any Disputes arising out of the Terms of Use or from use of Learntastic App or Website and the associated Content not settled by mutual agreement of the parties involved within fifteen (15) days after a party is provided written notice for settlement thereof shall be referred to arbitration of sole arbitrator jointly, appointed by the parties under the Indian Arbitration and Conciliation Act of 1996. The arbitration proceeding shall be conducted in Mumbai, India and shall be governed by and constructed in accordance with the laws of India. The language of the arbitration shall be English. The costs and expenses of the arbitration shall be borne as decided pursuant to the award.

Severability: If any provision of these terms shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these terms and shall not affect the validity and enforceability of any remaining provisions.

Survival: The provisions of these terms, which by their nature should survive the termination of these terms, shall survive such termination.

Waiver: No waiver of any provision of these terms by us shall be deemed a further or continuing waiver of such provision or any other provision, and our failure to assert any right or provision under these terms shall not constitute a waiver of such right or provision.

Assignment: These Terms of Service, and any rights and licenses granted hereunder, may not be transferred, or assigned by you, but may be assigned by the Company without restriction.

Force Majeure: The Company’s performance of its obligations under this Contract will be suspended for so long as the Company is prevented from doing so by an Event of Force Majeure. "Event of Force Majeure" shall mean such events or circumstances, which are beyond the control of the Company and which events could not have been reasonably foreseen or anticipated by the Company upon the exercise of due care and diligence, including but not limited to Learntastic App or Website’s operations being materially impaired, hampered, interrupted, prevented, suspended, postponed or discontinued due to the acts of God, earthquake, floods, riots, lockouts, war, civil disturbance, fire, accidents, armed conflict etc. government order or regulation, judicial order or decree (including without limitation injunctive relief, whether imposed on an industry wide basis or affecting the Company), failure of technical facilities or substantial impairment in obtaining facilities which makes the rendering of Learntastic App beyond the Company’s control.

We each agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration we each waive any right to a jury trial. We also both agree that you or we may bring suit in court to enjoin infringement or other misuse of intellectual property rights.
<h4>AMENDMENT</h4>
JSBB INFONET Pvt Ltd reserves the right, at any time and from time to time, to amend, supplement or modify the terms of these Terms of Service. We will post or display notices of material changes on the website and those changes will become effective fifteen (15) days after the date of posting. You are responsible for regularly reviewing the Terms of Service, including, without limitation, by checking the date of "Last Update" at the bottom of this document. Your continued use of the App after the changes become effective signifies your agreement to be bound by such modified Terms of Service. If you do not agree with the changes in the Terms of Service, your only recourse is stop using the App.

LAST UPDATED - September 4, 2023

CONTACT
JSBB INFONET Pvt Ltd Email: support@bleamlabs.com
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; Leanrtastic @ 2023 
          </p>
        </div>
      </div>
    </div>
  );
};
